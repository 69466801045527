import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import toNavbarProps from '../../../helpers/pageeditor/NavbarPropsHelper';
import useTranslation from '../../useTranslation';
import NavbarNav from '../Navbar/NavbarNav';
import { getFinalProps } from '../Navbar/NavbarWidget';
import type { NavbarWidgetProps } from '../Navbar/types';
import Head from 'next/head';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import { AdminPages } from '@aurora/shared-client/routes/adminRoutes';
import useAdminUserRoutes from '@aurora/shared-client/routes/useAdminRoutes';
import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import ThemeApplicator from '../../themes/ThemeApplicator/CachedThemeApplicator';
import localStyles from './CommunityMaintenancePlaceholder.module.pcss';

/**
 *  Community Maintenance placeholder component to display when the localized categories feature is enabled
 *  and there's any category mapped to a community language.
 *
 * @author Gaston Manzano
 */
const CommunityMaintenancePlaceholder: React.FC = () => {
  const cx = useClassNameMapper(localStyles);
  const { theme } = useContext(ThemeContext);
  const { navbar: navbarDefaultProps } = theme;
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.COMMUNITY_MAINTENANCE_PLACEHOLDER
  );
  const { Link } = useAdminUserRoutes();
  const { isAnonymous } = useRegistrationStatus();
  const {
    community: {
      title,
      userContext: { canUpdateNode: canUpdateCommunity }
    }
  } = useContext(AppContext);

  if (textLoading) {
    return null;
  }

  const navbarWidgetProps: NavbarWidgetProps = {
    style: navbarDefaultProps,
    links: { mainLinks: [], sideLinks: [] }
  };

  const navbarProps = toNavbarProps(navbarWidgetProps);
  const { navbarVariantProps, className: navbarClassName } = navbarProps;
  const finalProps = getFinalProps({
    navbarVariantProps,
    navbarWidgetProps,
    className: cx(navbarClassName),
    useNavMenuItems: false,
    useTransparentNavbar: false
  });

  const headTitle = formatMessage('headTitle', { communityTitle: title });

  return (
    <>
      <Head>
        <title>{headTitle}</title>
      </Head>
      <ThemeApplicator />
      <NavbarNav navProps={finalProps} />
      <div className={cx('lia-container')} data-testid="MaintenancePageError">
        <Icon size={IconSize.PX_120} icon={IconTypes.StopwatchIcon} />
        <EmptyState title={formatMessage('title')} description={formatMessage('description')}>
          {(canUpdateCommunity || isAnonymous) && (
            <Link route={AdminPages.AdminPage}>
              <a className={cx('d-block small')}>{formatMessage('redirect')}</a>
            </Link>
          )}
        </EmptyState>
      </div>
    </>
  );
};

export default CommunityMaintenancePlaceholder;
